


















































































import { mapActions, mapGetters, mapState } from 'vuex'
import { subHours, compareAsc } from 'date-fns'

import { defineComponent } from '@vue/composition-api'
import { TraineeImport } from '@/api/interfaces/traineeimport'
import { mapFields } from 'vuex-map-fields'
import { TraineeImportType } from '@/api/interfaces/traineeimporttype'
import { userConnectedHasRole } from '@/composables/UserGrant'
import Loading from '@/components/base/Loading.vue'
import ListImport from '@/views/dashboard/import/components/ListImport.vue'
import TopBar from '@/components/organisms/o-top-bar.vue'
import { QueryParams } from '@/store/api-plateform-utils'
import { TraineeImportTypeEnum } from '@/api/enums/traineeImportType'
import store from '@/store'

export default defineComponent({
  setup () {
    const canImportTrainee = () => userConnectedHasRole('ROLE_WRITE_TRAINEE_IMPORT')
    return {
      canImportTrainee,
    }
  },
  components: {
    ListImport,
    Loading,
    TopBar,
  },
  name: 'NeedTrainingCallCenterImport',
  data () {
    return {
      title: this.$t('menu.import_call_center_need_training'),
      dialogImport: false,
      file: null,
      refreshTimeoutId: 0,
      traineeImportType: null as TraineeImportType | null,
      isLoadingLastImports: false,
    }
  },
  computed: {
    ...mapState('import', {
      listTraineeImport: 'list',
    }),
    exampleFile (): string {
      return this.traineeImportType?.sampleFilePath as string
    },
    listImportRequest (): QueryParams {
      const request = {
        itemsPerPage: 10,
        'traineeImportType.name': [
          TraineeImportTypeEnum.NEED_TRAINING_CALL_CENTER,
        ],
      }
      return (request as unknown) as QueryParams
    },
  },
  async beforeMount () {
    this.traineeImportType = await this.loadTraineeImportTypeByName(TraineeImportTypeEnum.NEED_TRAINING_CALL_CENTER)
    this.isLoadingLastImports = true
    await this.loadLastImportsAndRefresh()
    this.isLoadingLastImports = false
  },
  destroyed () {
    window.clearTimeout(this.refreshTimeoutId)
  },
  methods: {
    ...mapActions('user', {
      sendErrorMessage: 'sendErrorMessage',
    }),
    ...mapActions('import', {
      upload: 'upload',
      loadTraineeImportTypeByName: 'loadTraineeImportTypeByName',
      loadLastImports: 'loadList',
    }),
    async importFile () {
      const formData = new FormData()
      formData.append('importFile', this.file as unknown as string)
      formData.append('traineeImportType', (this.traineeImportType as TraineeImportType).id as unknown as string)
      try {
        await this.upload(formData)
        this.file = null
        await this.loadLastImportsAndRefresh()
        this.dialogImport = true
      } catch (e: any) {
        this.sendErrorMessage(e.response ? e.response.data.error : e.message)
      }
    },
    async loadLastImportsAndRefresh () {
      try {
        const list: Array<TraineeImport> = await this.loadLastImports(
          this.listImportRequest,
        )
        const notFinishedImport: TraineeImport | undefined = list.find(
          (traineeImport: TraineeImport) => this.isNotFinished(traineeImport),
        )
        const timeLimit = subHours(new Date(), 12)

        if (notFinishedImport && compareAsc(new Date(notFinishedImport.createdAt as Date), timeLimit) > 0) {
          window.clearTimeout(this.refreshTimeoutId)
          this.refreshTimeoutId = window.setTimeout(async () => {
            await this.loadLastImportsAndRefresh()
          }, 1000)
        }
      } catch (e: any) {
        this.sendErrorMessage(e.response ? e.response.data.error : e.message)
      }
    },
    isNotFinished (traineeImport:TraineeImport): boolean {
      return traineeImport.status !== 'FINISH' && traineeImport.status !== 'WARNING'
    },
  },
  beforeRouteEnter (to, from, next) {
    store.commit('import/setList', [])
    next()
  },
})
